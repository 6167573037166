




















import { defineComponent, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import VueRecaptcha from 'vue-recaptcha'
import FormFeedback from '@/components/form/Feedback.vue'

export default defineComponent({
  name: 'FormRecaptcha',
  components: {
    ValidationProvider,
    FormFeedback,
    VueRecaptcha,
  },
  props: {
    sitekey: {
      type: String,
      default: '',
    },
  },
  setup() {
    const recaptchaValue = ref<string | null>(null)

    const onVerify = (res: string) => {
      recaptchaValue.value = res
    }

    const onError = () => {
      console.log('recaptcha:error')
      recaptchaValue.value = null
    }

    return {
      onError,
      onVerify,
      recaptchaValue,
    }
  },
})
