













































































import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { FileRecord } from 'vue-file-agent'
import { File } from '@/inc/types'

import UiLoadingDots from '@/components/ui/LoadingDots.vue'

export default defineComponent({
  name: 'FormFiles',
  inheritAttrs: false,
  components: { UiLoadingDots },
  props: {
    files: {
      type: Array as PropType<File[] | FileRecord[]>,
      default: () => [],
    },
    data: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
  },
  setup(props, ctx) {
    const fileAgent = ref()
    const fileRecords = ref(props.files)
    const isUploading = ref(false)
    const isMounted = ref(false)

    const onSelect = () => {
      ctx.emit('upload:start')
      isUploading.value = true
    }

    const onUploadError = (e: Event) => {
      isUploading.value = false
      console.error('upload:error', e)
      ctx.emit('upload:done')
    }

    const cleanFiles = () => {
      isUploading.value = false

      const files = fileRecords.value.map(record => {
        if (record.fileKey) {
          return {
            fileKey: record.fileKey,
            url: record.fileKey,
            name: record.savedName ? record.savedName : record.file.name,
            savedName: record.savedName ? record.savedName : record.file.name,
            size: record.file ? record.file.size : record.size,
            type: record.file ? record.file.type : record.type,
            ext: record.ext,
          }
        }

        return {
          fileKey: record.upload.data.fileKey,
          url: record.upload.data.fileKey,
          name: record.file.name,
          savedName: record.file.name,
          size: record.file.size,
          type: record.file.type,
          ext: record.ext,
        }
      })

      ctx.emit('upload:done', files)
    }

    const deleteFile = (file: FileRecord) => {
      const url = file.urlValue ? file.urlValue : file.upload.data.fileKey
      const uploadUrl =
        'https://camber-website-v1-cms.staging.epic-sys.io/api/v1/file/upload'
      const data = JSON.stringify({ fileKey: url })

      fileAgent.value.deleteUpload(uploadUrl, {}, data, data)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const record = fileRecords.value.find(r => r.fileKey === url)
      const index = fileRecords.value.indexOf(record)

      fileRecords.value.splice(index, 1)
      cleanFiles()
    }

    onMounted(() => {
      isMounted.value = true
    })

    return {
      fileAgent,
      fileRecords,
      isUploading,
      isMounted,
      cleanFiles,
      onSelect,
      onUploadError,
      deleteFile,
    }
  },
})
